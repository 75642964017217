import { parse } from 'query-string'

export const componentState = () => {
  const init = async () => {
    const params = parse(location.search)
    params.isSuccess = 0
    window.parent.postMessage(
      {
        source: 'settlePopResult',
        params: params
      },
      '*'
    )
    // handlerClose()
  }

  // const handlerClose = () => {
  //   HTMLIFrameElement.contentWindow.addEventListener('message', function({ data }) {
  //     if (!Util.isEmpty(data.source) && data.source === 'settlePopClose') {
  //       // sns 콜백 message 처리
  //       HTMLIFrameElement.contentWindow.self.close()
  //     }
  //   })
  // }

  init()

  return { init }
}
