<template>
  <div></div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'settle-cancel-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
